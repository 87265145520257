<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="search">
          <div class="row">
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                v-model="filter.dateFrom"
                id="kpi_nmh_filter_from"
                :label="$t('kpiNmh.date_from')"
                type="month"
                dateFormat="Y-m"
                class="m-b-0"
                :max-date="new Date('2023-08-31')"
              ></app-datepicker>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                v-model="filter.dateTo"
                id="kpi_nmh_filter_to"
                :label="$t('kpiNmh.date_to')"
                type="month"
                dateFormat="Y-m"
                class="m-b-0"
                :max-date="new Date('2023-08-31')"
              ></app-datepicker>
            </div>
            <div class="col-lg-2">
              <button class="btn btn-success m-t-20">{{ $t('buttons.search') }}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import Datepicker from '../form/Datepicker'

export default {
  name: 'KpiNmhFilterOld',
  components: {
    appDatepicker: Datepicker
  },
  computed: {
    filter () {
      return this.$store.getters['kpiNmh/filter']
    }
  },
  methods: {
    search () {
      this.$store.dispatch('kpiNmh/fetch')
      this.$store.dispatch('kpiNmh/fetchSettings')
    }
  },
  mounted () {
    this.search()
    this.$store.dispatch('kpiNmh/fetchMonthPrediction')
  }
}
</script>
